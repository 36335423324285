@font-face {
    font-family: 'Graphik';
    src: url('./fonts//Graphik-Extralight.eot?#iefix') format('embedded-opentype'),
        url('./fonts//Graphik-Extralight.otf') format('opentype'),
        url('./fonts//Graphik-Extralight.woff') format('woff'),
        url('./fonts//Graphik-Extralight.ttf') format('truetype'),
        url('./fonts//Graphik-Extralight.svg#Graphik-Light') format('svg');
    font-weight: 200;
    font-style: normal;
}


@font-face {
    font-family: 'Graphik';
    src: url('./fonts//Graphik-Light.eot?#iefix') format('embedded-opentype'),
        url('./fonts//Graphik-Light.otf') format('opentype'),
        url('./fonts//Graphik-Light.woff') format('woff'),
        url('./fonts//Graphik-Light.ttf') format('truetype'),
        url('./fonts//Graphik-Light.svg#Graphik-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url('./fonts//Graphik-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts//Graphik-Regular.otf') format('opentype'),
        url('./fonts//Graphik-Regular.woff') format('woff'),
        url('./fonts//Graphik-Regular.ttf') format('truetype'),
        url('./fonts//Graphik-Regular.svg#Graphik-Bold') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url('./fonts//Graphik-Medium.eot?#iefix') format('embedded-opentype'),
        url('./fonts//Graphik-Medium.otf') format('opentype'),
        url('./fonts//Graphik-Medium.woff') format('woff'),
        url('./fonts//Graphik-Medium.ttf') format('truetype'),
        url('./fonts//Graphik-Medium.svg#Graphik-Light') format('svg');
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: 'Graphik';
    src: url('./fonts//Graphik-Semibold.eot?#iefix') format('embedded-opentype'),
        url('./fonts//Graphik-Semibold.otf') format('opentype'),
        url('./fonts//Graphik-Semibold.woff') format('woff'),
        url('./fonts//Graphik-Semibold.ttf') format('truetype'),
        url('./fonts//Graphik-Semibold.svg#Graphik-Light') format('svg');
    font-weight: 600;
    font-style: normal;
}


@font-face {
    font-family: 'Graphik';
    src: url('./fonts//Graphik-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts//Graphik-Bold.otf') format('opentype'),
        url('./fonts//Graphik-Bold.woff') format('woff'),
        url('./fonts//Graphik-Bold.ttf') format('truetype'),
        url('./fonts//Graphik-Bold.svg#Graphik-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}